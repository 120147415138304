:root {
    --brown-back: rgba(226, 196, 161, 1);
    --brown-back-1: rgba(185, 114, 33, 1);
    --brown-back-2: rgba(181, 135, 80, 1);
    --brown-back-3: rgba(218, 158, 89, 1);
    --button-color: #05ce84;
    --button-pressed: #0b6a47;
    --burgundy: #800020;
    --honey: #dab420;
    --green: #75ba85;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: var(--brown-back);
    margin: 0;
    padding: 0;

    background: rgb(185, 114, 33);
    background: linear-gradient(90deg, --brown-back 0%, --brown-back-2 33%, --brown-back-3 67%, --brown-back 100%);
}
.plus-button {
    display: block;
    margin-left: 5%;
    margin-right: 5%;
    background-color: --button-color;
    color: var(--button-color);
    width: 90%;
    height: 100em;
    cursor: pointer;
    border-radius: 0.6rem;
    font-size: 1.2em;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
    opacity: 0.1;
}

.plus-button:hover {
    background-color: --button-pressed;
    color: var(--green);
    transform: scale(1.1);
}

.container {
    /* Adjust this color to match the kombucha color */
    background-size: 50px 50px;
    margin: 10px auto;
    padding: 10px;

}

h1 {
    text-align: center;
    color: #333;
}

.sort-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.sort-container label {
    margin-right: 10px;
    font-weight: bold;
}

.sort-container select,
.sort-container button {
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.sort-container button {
    background-color: #28a745;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sort-container button:hover {
    background-color: #218838;
}

#orders {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    /* Increased gap */
    justify-content: center;
}

.order-box {
    background-color: rgb(230, 252, 247, 0.6);
    border-radius: .5em;
    padding: 10px;
    /* Reduced padding */
    width: 180px;
    /* Reduced width */
    height: 180px;
    /* Reduced height */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    position: relative;
    text-align: center;
    transition: transform 0.3s;
    /* Added for zoom effect */
    cursor: pointer;
    /* Indicate clickable */
    z-index: 10;
}

.order-box:hover {
    transform: scale(1.1);
    /* Zoom effect */
}

.order-number {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    background-color: #444;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    border: none;
}

.order-number:hover::after {
    content: attr(title);
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8em;
    white-space: nowrap;
    z-index: 1;
}

.order-box h2 {
    margin: 10px 0 5px 0;
    font-weight: bold;
    font-size: 1.3em;
    color: #444;
}

.order-box h1 {
    display: inline-block;
    /* Burgundy */
    padding: 5px 10px;

    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    text-align: center;
    /* Default color */
}

.order-box p {
    margin: 5px 0;
    color: #666;
    font-size: 0.9em;
}

.order-date,
.ready-date {
    font-weight: bold;
    color: #666;
    font-size: 0.9em;
}

.order-date {
    bottom: 10px;
    left: 0;
    right: 0;
}

.ready-date {
    bottom: 10px;
    left: 0;
    right: 0;
}

.order-stats {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 7%;
    right: 7%;
    font-weight: bold;
    color: #666;
    font-size: 0.9em;
    padding: 10px;
    background-color: var(--brown-back);

}

.order-stats p {
    margin: 5px 0;
    color: #666;
    font-size: 0.9em;
}

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
}

.form-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    border: 1px solid #888;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    z-index: 20;

    overflow: auto;
    /* Allows scrolling within the form */
    max-height: 90vh;
    /* Ensures the form doesn't exceed the viewport height */
    background-color: white;
    border-radius: 5px;
}

.form-container {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    text-align: center;
}

.form-container select,
.form-container input[type="text"],
.form-container input[type="date"] {
    width: calc(100% - 30px);
    /* Adjusted width */
    padding: 10px;
    margin: 5px 15px 10px 15px;
    /* Adjusted margin */
    border: none;
    background: #f1f1f1;
    border-radius: 5px;
    text-align: center;
    /* Centering text */
}

.form-container select:focus,
.form-container input[type="text"]:focus,
.form-container input[type="date"]:focus {
    background-color: #ddd;
    outline: none;
}

.form-container .btn {
    background-color: #04AA6D;
    color: white;
    transform: scale(1.1);
    border-radius: 50%;
}

.form-btn,
.form-cancel {
    background-color: #04AA6D;
    color: white;
    padding: 5px 10px;
    border: 2px solid #800020;
    /* Burgundy */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    text-align: left;
    gap: 10px;
}

order-box h1 {
    display: inline-block;
    /* Burgundy */
    padding: 5px 10px;
    /* Burgundy */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    text-align: center;
}

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--burgundy);
    color: white;
    border: none;
    border-radius: 15%;
    padding: 5px 10px;
    cursor: pointer;
    display: none;
    /* Initially hidden */
}

.delete-button:hover {
    background-color: #cc0000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    transition: box-shadow 0.3s;
}