body {
    font-family: 'Montserrat', sans-serif;


    background: rgb(185, 114, 33);
    background: linear-gradient(90deg, rgba(185, 114, 33, 1) 0%, rgba(181, 135, 80, 1) 33%, rgb(218, 158, 89) 67%, rgba(185, 114, 33, 1) 100%);


    margin: 0;
    padding: 0;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 20px 20px;
    /* Increased padding for taller bar */
    color: white;
    position: sticky;
}

.nav-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 50px;
    /* Adjusted for the plus button */
}

.nav-link {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.nav-buttons button:hover {
    background-color: #555;
}

/* CSS for the bubbles */
.bubbles {
    position: fixed;
    /* Cover the entire screen */
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0.1;
    pointer-events: none;
}

.bubble {
    position: absolute;
    background: rgba(137, 65, 2, 0.5);
    border-radius: 50%;
    opacity: 0.6;
    z-index: 0.1;
}