.root {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
}

.form-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    border: 1px solid #888;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    z-index: 20;

    overflow: auto;
    /* Allows scrolling within the form */
    max-height: 90vh;
    /* Ensures the form doesn't exceed the viewport height */
    background-color: white;
    border-radius: 5px;
}

.form-container {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    text-align: center;
}

.form-container select,
.form-container input[type="text"],
.form-container input[type="date"] {
    width: calc(100% - 30px);
    /* Adjusted width */
    padding: 10px;
    margin: 5px 15px 10px 15px;
    /* Adjusted margin */
    border: none;
    background: #f1f1f1;
    border-radius: 5px;
    text-align: center;
    /* Centering text */
}

.form-container select:focus,
.form-container input[type="text"]:focus,
.form-container input[type="date"]:focus {
    background-color: #ddd;
    outline: none;
}

.form-container .btn {
    background-color: #04AA6D;
    color: white;
    transform: scale(1.1);
    border-radius: 50%;
}

.form-btn,
.form-cancel {
    background-color: #04AA6D;
    color: white;
    padding: 5px 10px;
    border: 2px solid #800020;
    /* Burgundy */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    text-align: left;
    gap: 10px;
}

order-box h1 {
    display: inline-block;
    /* Burgundy */
    padding: 5px 10px;
    /* Burgundy */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    text-align: center;
}

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--burgundy);
    color: white;
    border: none;
    border-radius: 15%;
    padding: 5px 10px;
    cursor: pointer;
    display: none;
    /* Initially hidden */
}

.delete-button:hover {
    background-color: #cc0000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    transition: box-shadow 0.3s;
}